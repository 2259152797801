<template>
  <section class="pharmacies-banner">
    <div class="pharmacies-downloads">
      <div class="container">
        <div
          @click.prevent="toPreviousPage"
          class="pharmacies-banner__back-mobile"
        >
          Повернутися назад
        </div>
        <div class="row">
          <div class="col-xx-lg-5 col-x-lg-5 col-lg-5 col-md-6 col-xs-12">
            <h4 class="pharmacies-downloads__title">
              Список аптек, що беруть участь у програмі:
            </h4>
          </div>
          <div class="col-xx-lg-7 col-x-lg-7 col-lg-7 col-md-6 col-xs-12">
            <div class="pharmacies-downloads__links">
              <a
                download
                href="/List_of_pharmacies_2024.pdf"
                class="pharmacies-downloads__link pharmacies-downloads__link_pdf"
              >
                <span class="pharmacies-downloads__link-text"
                >Завантажити у форматі</span
                >
                PDF
              </a>
              <a
                download
                href="/List_of_pharmacies_2024.xls"
                class="pharmacies-downloads__link pharmacies-downloads__link_xls"
              >
                <span class="pharmacies-downloads__link-text"
                >Завантажити у форматі</span
                >
                EXCEL
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div @click.prevent="toPreviousPage" class="pharmacies-banner__back">
      Повернутися назад
    </div>
    <div class="pharmacies-banner__content">
      <div class="pharmacies-search">
        <div class="container">
          <h2 class="pharmacies-search__title">
            Аптеки, що беруть участь у програмі
            <span>ТерапіяПЛЮС</span>
          </h2>
          <div class="pharmacies-search__description">
            Будь ласка, зверніть увагу, що під час воєнних дій на території України, робота аптек залежить від ситуації
            в регіоні, де вони розташовані. Обов'язково зателефонуйте в аптеку перед візитом, аби з'ясувати режим
            роботи, пересвідчитись, що необхідні препарати є в наявності, а також підтвердити, що аптека зберігає свою
            участь у Програмі "ТерапіяПЛЮС"
          </div>
          <div class="pharmacies-search__form">
            <div class="pharmacies-search__form-field">
              <input
                type="text"
                autocomplete="off"
                @focus="isSearchFocus = true"
                @blur="isSearchFocus = false"
                v-model.trim="formData.name"
                v-on:keyup.enter="submitForm"
                placeholder="Почніть вводити назву або адресу..."
              />
              <div
                class="pharmacies-search__form-clear"
                v-show="formData.name"
                @click="clearSearchField"
                :class="isSearchFocus ? 'active' : 'active'"
              ></div>
              <!--X button always must be active-->
            </div>
            <div
              class="pharmacies-search__form-submit"
              :class="displayLoader ? 'loading' : '' "
            >
              <input type="button" @click="submitForm" value="Шукати"/>
              <div
                class="pharmacies-search__form-submit-loader"
                v-if="displayLoader"
              ></div>
            </div>
          </div>
          <div class="pharmacies-filter__title_error" v-if="showTitleError">
            На жаль за вашим запитом
            <span class="pharmacies-filter__title_quote"
            >«{{
                formData.name === "" ? formData.letter : formData.name
              }}»</span
            >
            немає жодного результату.
            <br/>Перевірте правильність написання або виберіть аптеку зі
            списку.
          </div>
        </div>
      </div>
      <div class="pharmacies-filter">
<!--        <div class="container">-->
<!--          <div class="pharmacies-filter__title">-->
<!--            Фільтрувати за першою буквою назви міста:-->
<!--          </div>-->
<!--          <div class="pharmacies-filter__list">-->
<!--            <div-->
<!--              class="pharmacies-filter__list-item tns-item"-->
<!--              v-for="(letter, index) in letters"-->
<!--              :class="letter.selected ? 'active' : ''"-->
<!--              :key="index"-->
<!--              @click="selectLetter(letter.name, index)"-->
<!--            >-->
<!--              {{ letter.name }}-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->
      </div>
    </div>
  </section>
</template>
<script>
import {tns} from "../../../node_modules/tiny-slider/src/tiny-slider";
import {mapGetters, mapMutations} from "vuex";

export default {
  data() {
    return {
      displayLoader: false,
      DS: [],
      showTitleError: false,
      formData: {
        name: "",
        letter: ""
      },
      isSearchFocus: false,
      letters: [
        {
          name: "А",
          selected: false
        },
        {
          name: "Б",
          selected: false
        },
        {
          name: "В",
          selected: false
        },
        {
          name: "Г",
          selected: false
        },
        {
          name: "Ґ",
          selected: false
        },
        {
          name: "Д",
          selected: false
        },
        {
          name: "Е",
          selected: false
        },
        {
          name: "Є",
          selected: false
        },
        {
          name: "Ж",
          selected: false
        },
        {
          name: "З",
          selected: false
        },
        {
          name: "І",
          selected: false
        },
        {
          name: "Ї",
          selected: false
        },
        {
          name: "Й",
          selected: false
        },
        {
          name: "К",
          selected: false
        },
        {
          name: "Л",
          selected: false
        },
        {
          name: "М",
          selected: false
        },
        {
          name: "Н",
          selected: false
        },
        {
          name: "О",
          selected: false
        },
        {
          name: "П",
          selected: false
        },
        {
          name: "Р",
          selected: false
        },
        {
          name: "С",
          selected: false
        },
        {
          name: "Т",
          selected: false
        },
        {
          name: "У",
          selected: false
        },
        {
          name: "Ф",
          selected: false
        },
        {
          name: "Х",
          selected: false
        },
        {
          name: "Ц",
          selected: false
        },
        {
          name: "Ч",
          selected: false
        },
        {
          name: "Ш",
          selected: false
        },
        {
          name: "Щ",
          selected: false
        },
        {
          name: "Ю",
          selected: false
        },
        {
          name: "Я",
          selected: false
        }
      ]
    };
  },
  computed: {
    ...mapGetters(["getResponsive", "getPharmaciesContext"])
  },
  created() {
    this.submitForm();
  },
  watch: {
    "formData.name": function () {
      if (this.showTitleError) this.showTitleError = false;
    },

    DS: function (value) {
      if (value.length > 0) {
        this.showTitleError = false;
      } else {
        this.showTitleError = true;
      }
    }
  },
  methods: {
    ...mapMutations(["setPharmaciesList"]),
    toPreviousPage() {
      this.$router.go(-1);
    },
    clearSearchField() {
      this.formData.name = "";
    },
    selectLetter(selectedLetter, index) {
      this.formData.letter = selectedLetter;

      this.letters.forEach((letter, i) => {
        if (i == index) {
          if (this.letters[index].selected) {
            this.letters[index].selected = false;
            this.formData.letter = "";
          } else {
            this.letters[index].selected = true;
            this.formData.letter = this.letters[index].name;
          }
        } else {
          letter.selected = false;
        }
      });
      this.submitForm();
    },
    submitForm() {
      this.getDrugStores(
        this.formData.name,
        this.formData.letter,
        "",
        0,
        0,
        0,
        false
      );
    },
    getDrugStores(Address, Letter, City, Lat, Lon, Radius, workAround) {

      this.displayLoader = true;
      var data = new FormData();
      data.append("Address", Address);
      data.append("Letter", Letter.toLowerCase());
      data.append("City", City);
      data.append("Lat", Lat);
      data.append("Lon", Lon);
      data.append("Radius", Radius);
      this.axios
        .post(
          "https://terapia-plus-api.azurewebsites.net/api/Map/getDrugStores",
          data
        )
        .then(res => {
          console.log(res);

          if (workAround) {
            this.drugStores = [];
            res.data.forEach(e => {
              if (e.Worktime == "ЦІЛОДОБОВО") this.drugStores.push(e);
            });
          } else {
            this.drugStores = res.data;
          }
          var tempPharmacies = [];

          this.drugStores.forEach(d => {
            var temp = {
              position: {
                lat: d.Lat,
                lng: d.Lon
              },
              title: d.Name,
              city: d.City,
              address: d.City + ", " + d.Address,
              phones: [d.Tel1, d.Tel2],
              timework: {
                weekdays: d.Worktime,
                weekend: ""
              }
            };
            tempPharmacies.push(temp);
          });
          this.DS = tempPharmacies;
          this.setPharmaciesList(tempPharmacies);
          this.getPharmaciesContext.getPharmacies();
          this.displayLoader = false;
        })
    }
  },
  mounted() {
    if (this.getResponsive) {
      tns({
        container: ".pharmacies-filter__list",
        items: 7,
        controls: false,
        nav: false,
        autoplay: false,
        preventScrollOnTouch: true,
        preventActionWhenRunning: true,
        loop: false,
        swipeAngle: 50
      });
    }
  }
};
</script>
