<template>
  <div class="pharmacies-item">
    <div class="pharmacies-item__name">{{ name }}</div>
    <div class="pharmacies-item__city">{{ city }}</div>
    <div class="pharmacies-item__address">{{ address }}</div>
    <div class="pharmacies-item__phone" v-if="phone1.length > 4 ">
      {{ phone1 }}
      <span v-if="phone2.length > 0">,</span>
      {{ phone2 }}
    </div>
    <!-- <div class="pharmacies-item__phone" v-if="phone2.length>0">{{phone2}}</div> -->
    <!--    <div class="pharmacies-item__timework" v-if="timework.length > 0">-->
    <!--      {{ timework }}-->
    <!--    </div>-->
    <!--    <div class="pharmacies-item__timework" v-if="timework.length == 0">-->
    <!--      Пн-Нд 08:00-20:00-->
    <!--    </div>-->
    <div class="pharmacies-item__link">
      <a :href="link" target="_blank">Показати на мапі</a>
    </div>
  </div>
</template>

<script>
export default {
  name: "PharmaciesItem",
  props: ["name", "city", "address", "phone1", "phone2", "timework", "link"]
};
</script>
